  import React from 'react'
  import Layout from '../../components/App/Layout'
  import Navbar from '../../components/App/NavbarRV'
  import Footer from '../../components/App/FooterRV'
  import KDPImagePage from '../../assets/images/pages/4-0-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li>Qui sommes nous ? </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Bienvenue sur RVExpertises</h1>

<p>Retrouvez ici nos pages juridiques : </p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Qui sommes nous ? " />
</div>


<ul>
    <li><a href="https://www.rvexpertises.fr/legal/mentions-legales">mentions légales</a></li>
    <li><a href="https://www.rvexpertises.fr/legal/cgu">conditions générales d’utilisation </a></li>
</ul>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/legal/' className='active'>Qui sommes nous ? </a></li><li><a href='/legal/cgu/' >CGU</a></li><li><a href='/legal/mentions-legales/' >Mentions légales</a></li><li><a href='/legal/politique-confidentialite/' >Politique de confidentialité</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details